import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import type {ReactNode} from 'react'
import React from 'react'
import styled, {keyframes} from 'styled-components'
import {useElementSize} from 'usehooks-ts'

import {tokens} from '../../tokens'

export interface TooltipProps {
    /**
     * The element that toggles the tooltip
     */
    children: ReactNode
    /**
     * The content shown inside the tooltip.
     */
    content: ReactNode
    /**
     * Event handler called when the tooltip is toggled.
     */
    onOpenChange?: (isOpen: boolean) => void
    /**
     * By default, screenreaders will announce the content inside
     * the component. If this is not descriptive enough, or you have
     * content that cannot be announced, use `aria-label` as a more
     * descriptive label.
     */
    'aria-label'?: string
    /**
     * Use this option if the tooltip would otherwise overlay important information. For consistency, the default positioning should be preferred.
     * @default 'top'
     */
    side?: 'top' | 'right' | 'bottom' | 'left'

    /**
     * Use this option if the tooltip would otherwise overlay important information. For consistency, the default alignment should be preferred.
     * @default 'center'
     */
    align?: 'start' | 'center' | 'end'
    /**
     * * If set to false, the tooltip content will be rendered next to the trigger element. By default, the content is portalled into the body.
     * @default true
     */
    portalled?: boolean
    /**
     * The controlled open state of the tooltip. Must be used in conjunction with onOpenChange.
     */
    open?: boolean
}

// This is an arbitrary value. I felt like it was enough
// to draw attention to the tooltip without becoming annoying
const translateDistance = '3px'

const slideUpAndFade = keyframes({
    '0%': {transform: `translateY(${translateDistance})`, opacity: 0},

    '100%': {transform: 'translateY(0)', opacity: 1}
})

const slideRightAndFade = keyframes({
    '0%': {transform: `translateX(-${translateDistance})`, opacity: 0},

    '100%': {transform: 'translateX(0)', opacity: 1}
})

const slideDownAndFade = keyframes({
    '0%': {transform: `translateY(-${translateDistance})`, opacity: 0},

    '100%': {transform: 'translateY(0)', opacity: 1}
})

const slideLeftAndFade = keyframes({
    '0%': {transform: `translateX(${translateDistance})`, opacity: 0},

    '100%': {transform: 'translateX(0)', opacity: 1}
})

const fadeOut = keyframes({
    '0%': {opacity: 1},

    '100%': {opacity: 0}
})

const Content = styled(TooltipPrimitive.Content)`
    /* Tooltip content should always be brief. But in case someone is
    unaware of this, we prefer break up text into multiple lines */
    max-width: 40ch;
    padding: ${tokens.spacing8} ${tokens.spacing12};
    color: ${tokens.colorContentStaticInverse};
    font-size: ${tokens.fontMedium};
    line-height: ${tokens.lineHeight3};
    background-color: ${tokens.colorBackgroundStaticInverse};
    border-radius: ${tokens.arc4};
    box-shadow: ${tokens.shadowRaiseQuiet};
    z-index: ${tokens.zIndexTooltip};

    /* The content should idealy be shown within 0.1 seconds after
    delay. The fast token is the closest we have to this
    https://ux.stackexchange.com/a/119975 */
    animation-duration: ${tokens.fast};
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

    &[data-state='delayed-open'] {
        &[data-side='top'] {
            animation-name: ${slideDownAndFade};
        }

        &[data-side='right'] {
            animation-name: ${slideLeftAndFade};
        }

        &[data-side='bottom'] {
            animation-name: ${slideUpAndFade};
        }

        &[data-side='left'] {
            animation-name: ${slideRightAndFade};
        }
    }

    &[data-state='closed'] {
        animation-name: ${fadeOut};
    }
`

// This is an arbitrary value. It just seems to fit well
// with our styling of the tooltip
const arrowWidth = 14
export const Tooltip = ({
    children,
    content,
    onOpenChange,
    side = 'top',
    align = 'center',
    portalled = true,
    open,
    'aria-label': ariaLabel
}: TooltipProps) => {
    const [ref, {width, height}] = useElementSize<HTMLButtonElement>()

    if (!content || content === '') {
        return <>{children}</>
    }

    const ContentWrapper = portalled ? TooltipPrimitive.Portal : React.Fragment

    // Radix doesn't center the arrow in relation to the trigger
    // content out-of-the-box.
    const offset = ['top', 'bottom'].includes(side)
        ? width / 2 - arrowWidth / 2
        : height / 2 - arrowWidth / 2

    return (
        // The delay should be between 300 and 500 ms https://ux.stackexchange.com/a/119975
        <TooltipPrimitive.Root delayDuration={300} onOpenChange={onOpenChange} open={open}>
            <TooltipPrimitive.Trigger ref={ref} asChild>
                {children}
            </TooltipPrimitive.Trigger>
            <ContentWrapper>
                <Content
                    side={side}
                    align={align}
                    sideOffset={2}
                    aria-label={ariaLabel}
                    data-telescope="tooltip"
                >
                    {content}
                    <TooltipPrimitive.Arrow
                        fill={tokens.colorBackgroundStaticInverse}
                        offset={offset}
                        width={arrowWidth}
                        height={arrowWidth / 2}
                    />
                </Content>
            </ContentWrapper>
        </TooltipPrimitive.Root>
    )
}

export const TooltipProvider = TooltipPrimitive.Provider
