import * as RadixAccordion from '@radix-ui/react-accordion'
import React from 'react'

import {
    Chevron,
    Content,
    ContentWrapper,
    Footer,
    Header,
    Item,
    SpacedAccordion,
    TitleContent,
    TitleWrapper,
    Trigger
} from './accordion.styles'

import type {BadgeProps} from '../badge'
import {Badge} from '../badge'
import {Count} from '../count'
import {Text} from '../text'

type OmittedAccordionProps = 'asChild' | 'dir' | 'orientation' | 'type'

type SingleAccordionProps = Omit<RadixAccordion.AccordionSingleProps, OmittedAccordionProps> & {
    /**
     * Determines whether one or multiple items can be opened at the same time. Recommended to use multiple.
     * @default 'multiple'
     */
    type: 'single'
}
type MultipleAccordionProps = Omit<RadixAccordion.AccordionMultipleProps, OmittedAccordionProps> & {
    type?: 'multiple'
}

export type AccordionProps = SingleAccordionProps | MultipleAccordionProps

export const Accordion = ({children, ...props}: AccordionProps) => {
    if (props.type === 'single') {
        props.collapsible = props.collapsible ?? true
        return (
            <SpacedAccordion data-telescope="accordion" {...props}>
                {children}
            </SpacedAccordion>
        )
    }
    return (
        <SpacedAccordion data-telescope="accordion" type="multiple" {...props}>
            {children}
        </SpacedAccordion>
    )
}

interface ItemProps {
    /**
     * When true, prevents the user from interacting with the item.
     * @default false
     */
    disabled?: boolean
    /**
     * A unique value for the item.
     */
    value: string
    /**
     * Children must only be an `Accordion.Header` followed by an `Accordion.Content`, optionally follwed by an `Accordion.Footer`.
     */
    children?: React.ReactNode
}

const AccordionItem = (props: ItemProps) => <Item {...props} />

export interface AccordionBadgeProps extends Omit<BadgeProps, 'children'> {
    /**
     * Provide the badge with a label.
     */
    content: React.ReactNode
}

export interface HeaderProps extends Pick<RadixAccordion.AccordionTriggerProps, 'onClick'> {
    /** Set the correct heading level based on the placement on the page */
    headingLevel: 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    /** Add heading text to an item header */
    heading: string
    /** Add a description to an item header */
    description?: string
    /** Add an Icon to an item header */
    Icon?: React.FC<{size?: any; color?: any}>
    /** Add a count to an item header */
    count?: number
    /** Add a badge to an item header */
    badgeProps?: AccordionBadgeProps
}

const AccordionHeader = ({
    headingLevel,
    heading,
    description,
    Icon,
    count,
    badgeProps,
    ...props
}: HeaderProps) => {
    let badgeContent, otherBadgeProps
    if (badgeProps) {
        const {content, ...otherProps} = badgeProps
        badgeContent = content
        otherBadgeProps = otherProps
    }

    return (
        <RadixAccordion.Header asChild>
            <Header as={headingLevel}>
                <Trigger {...props}>
                    <TitleWrapper>
                        {Icon && <Icon size={24} />}
                        <TitleContent>
                            <Text
                                as="span"
                                variant="medium-default"
                                weight="medium"
                                color="colorContentInteractive"
                                maxLines={2}
                            >
                                {heading}
                            </Text>
                            {description && (
                                <Text
                                    as="span"
                                    variant="small-subtle"
                                    color="colorContentInteractiveQuiet"
                                    maxLines={2}
                                >
                                    {description}
                                </Text>
                            )}
                        </TitleContent>
                    </TitleWrapper>
                    {count && <Count>{count}</Count>}
                    {badgeContent && otherBadgeProps && (
                        <Badge {...otherBadgeProps}>{badgeContent}</Badge>
                    )}
                    <Chevron />
                </Trigger>
            </Header>
        </RadixAccordion.Header>
    )
}

const AccordionContent = ({children, ...props}: {children?: React.ReactNode}) => (
    <Content>
        <ContentWrapper {...props}>{children}</ContentWrapper>
    </Content>
)

Accordion.Item = AccordionItem
Accordion.Header = AccordionHeader
Accordion.Content = AccordionContent
Accordion.Footer = Footer
