import {useId} from '@reach/auto-id'
import React from 'react'
import styled from 'styled-components'

import {Warning} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'
import {useLocalisation} from '../../utils/localisation'
import type {CheckboxGroupProps} from '../checkbox'
import {CheckboxGroup} from '../checkbox'
import type {HelpPopoverProps} from '../help-popover'
import {HelpPopover, Trigger as HelpPopoverTrigger} from '../help-popover'
import {Inline} from '../inline'
import type {RadioGroupProps} from '../radio-button'
import {RadioGroup} from '../radio-button'
import {Stack} from '../stack'
import {Text} from '../text'
import {VisuallyHidden} from '../visually-hidden'

//#region Components

type FormControlProps = {
    /**
     * Any of the other FormControl components and the form element you want to render
     */
    children: React.ReactNode
    /**
     * Optional class name for extending styles
     */
    className?: string
    /**
     * Define a maximum width of the form control
     */
    maxWidth?: string
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean
}

export const FormControl = ({
    children,
    className,
    maxWidth,
    skeleton = false,
    ...props
}: FormControlProps) => {
    const labelId = useId()
    const inputId = useId()
    const captionId = useId()

    return (
        <FormControlContext.Provider
            value={{
                labelId,
                inputId,
                captionId,
                skeleton
            }}
        >
            <FormControlWrapper className={className} $maxWidth={maxWidth} {...props}>
                {children}
            </FormControlWrapper>
        </FormControlContext.Provider>
    )
}

type LabelComponentProps = {
    /**
     * The label text
     */
    children: React.ReactNode
    /**
     * Optional class name for extending styles
     */
    className?: string
    /**
     * Optional ID of the input to associate with the label. Uses automatically generated ID otherwise
     */
    htmlFor?: string
}

const LabelComponent = ({children, className, htmlFor}: LabelComponentProps) => {
    const {labelId, skeleton} = useFormControlContext()
    const {inputId} = useFormControlContext()

    return (
        <Label
            id={labelId}
            // Will be fixed in https://linear.app/pleo/issue/WEB-789/replace-react-docgen-typescript-with-react-docgen
            // @ts-expect-error htmlFor prop missing
            htmlFor={htmlFor ?? inputId}
            as="label"
            className={className}
            skeleton={skeleton}
        >
            {children}
        </Label>
    )
}

type HelpComponentProps = Omit<HelpPopoverProps, 'aria-label' | 'size'>
const HelpComponent = ({children, ...rest}: HelpComponentProps) => {
    const {labelId, skeleton} = useFormControlContext()
    return (
        <HelpPopover aria-labelledby={labelId} skeleton={skeleton} {...rest}>
            {children}
        </HelpPopover>
    )
}

type HintTextComponentProps = {
    /**
     * The caption text
     */
    children: React.ReactNode
    /**
     * Optional class name for extending styles
     */
    className?: string
    /**
     * Optional ID for the element. Uses automatically generated ID otherwise
     */
    id?: string
}

const HintTextComponent = ({children, className, id}: HintTextComponentProps) => {
    const {captionId, skeleton} = useFormControlContext()

    return (
        <Caption id={id ?? captionId} className={className} skeleton={skeleton}>
            {children}
        </Caption>
    )
}

const LabelWrapper = styled.div`
    display: flex;
    flex-flow: wrap;
    gap: ${tokens.spacing8};
    align-items: center;

    ${HelpPopoverTrigger} {
        display: flex;
        border-radius: ${tokens.arc99999};
    }
`

type LabelWrapperComponentProps = {
    /**
     * Necessary if you want to render a `Help` option or other elements next to the label
     */
    children: React.ReactNode
    /**
     * Optional class name for extending styles
     */
    className?: string
}

const LabelWrapperComponent = ({children, className}: LabelWrapperComponentProps) => {
    return <LabelWrapper className={className}>{children}</LabelWrapper>
}

//#endregion Components

//#region Styles

export const FormControlWrapper = styled.div<{$maxWidth?: string}>`
    width: 100%;
    max-width: ${({$maxWidth}) => $maxWidth};

    > :not(:first-child) {
        margin-top: ${tokens.spacing6};
    }
`

export const Label = styled(Text).attrs({
    variant: 'medium-default',
    color: 'shade800'
})`
    ${FormControlWrapper} & {
        align-self: start;
    }
`

const Caption = styled(Text).attrs({
    variant: 'small-subtle',
    color: 'shade600'
})`
    &:not(:first-child) {
        margin-top: ${tokens.spacing2};
    }
`

const ErrorText = styled(Text).attrs({
    variant: 'small-subtle',
    color: 'colorContentNegative',
    align: 'left'
})`
    line-height: ${tokens.lineHeight1};
`

type BaseErrorProps = {
    /**
     * The error message
     */
    children?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export const BaseError = ({children, ...props}: BaseErrorProps) => {
    const translations = useLocalisation()

    return (
        <Inline space={4} alignItems="start" {...props}>
            <Warning aria-hidden="true" size={16} color={tokens.colorContentNegative} />
            <VisuallyHidden>{translations.FormControl.Error}:</VisuallyHidden>
            <ErrorText>{children}</ErrorText>
        </Inline>
    )
}

const GroupWrapper = styled.div`
    &:not(:first-child) {
        margin-top: ${tokens.spacing16};
    }
`

const RadioGroupComponent = ({children, ...rest}: RadioGroupProps) => (
    <GroupWrapper>
        <RadioGroup {...rest}>
            <Stack space={16}>{children}</Stack>
        </RadioGroup>
    </GroupWrapper>
)

const CheckboxGroupComponent = ({children, ...rest}: CheckboxGroupProps) => (
    <GroupWrapper>
        <CheckboxGroup {...rest}>{children}</CheckboxGroup>
    </GroupWrapper>
)

//#endregion Styles

//#region Context

type FormControlContextType = {
    labelId?: string
    inputId?: string
    captionId?: string
    skeleton?: boolean
}

const FormControlContext = React.createContext<FormControlContextType>({})

const useFormControlContext = () => React.useContext(FormControlContext)

export const useFormControlId = (id?: string) => {
    const formControlContext = useFormControlContext()
    const fallbackId = useId(id)

    return formControlContext.inputId || fallbackId
}

export const useFormControlCaptionId = () => {
    const formControlContext = useFormControlContext()

    return formControlContext.captionId
}

//#endregion Context

FormControl.Label = LabelComponent
FormControl.Error = BaseError
FormControl.HintText = HintTextComponent
FormControl.Help = HelpComponent
FormControl.LabelWrapper = LabelWrapperComponent
FormControl.RadioGroup = RadioGroupComponent
FormControl.CheckboxGroup = CheckboxGroupComponent
